
import FAQAccordionItem from './FAQAccordionItem.vue'

export default {
  name: 'FAQAccordion',
  components: {
    FAQAccordionItem
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
