
export default {
  name: 'FAQAccordionItem',
  props: {
    question: {
      type: String,
      required: true
    },
    answer: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false // To track whether the accordion is open or closed
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen // Toggle the open/close state
    }
  }
}
