
import AtomIcon from '~/components/atoms/common/AtomIcon.vue'

export default {
  name: 'TestimonialCard',
  components: { AtomIcon },
  props: {
    initials: {
      type: [String, Object],
      required: true
    },
    text: {
      type: [String, Object],
      required: true
    }
  }
}
