
import TestimonialCard from './TestimonialCard.vue'

export default {
  name: 'TestimonialCarousel',
  components: {
    TestimonialCard
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentIndex: 0,
      cardsPerView: 4, // Default number of visible cards
      isDragging: false,
      startPosX: 0,
      currentTranslateX: 0,
      prevTranslateX: 0
    }
  },
  computed: {
    totalSlides() {
      return Math.ceil(this.items.length / this.cardsPerView)
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize() // Call it immediately to set initial view
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.cardsPerView) {
        this.currentIndex++
      }
    },
    startDrag(event) {
      this.isDragging = true
      this.startPosX = event.type.includes('mouse') ? event.pageX : event.touches[0].clientX
      this.prevTranslateX = this.currentTranslateX
    },
    dragging(event) {
      if (!this.isDragging) return
      const currentPosX = event.type.includes('mouse') ? event.pageX : event.touches[0].clientX
      const diff = currentPosX - this.startPosX
      this.currentTranslateX = this.prevTranslateX + diff

      // Reference the carousel via ref
      if (this.$refs.carousel) {
        this.$refs.carousel.style.transform = `translateX(${this.currentTranslateX}px)`
      }
    },
    endDrag() {
      if (!this.isDragging) return
      this.isDragging = false

      // Detect if the drag should result in a slide move
      const moveThreshold = 50 // Minimum pixel move to trigger slide
      const moved = this.currentTranslateX - this.prevTranslateX

      if (moved > moveThreshold && this.currentIndex > 0) {
        this.prevSlide() // Move to previous card
      } else if (
        moved < -moveThreshold &&
        this.currentIndex < this.items.length - this.cardsPerView
      ) {
        this.nextSlide() // Move to next card
      }

      // Reset to correct position after dragging
      if (this.$refs.carousel) {
        this.$refs.carousel.style.transform = `translateX(-${
          this.currentIndex * (100 / this.cardsPerView)
        }%)`
      }
    },
    handleResize() {
      if (window.innerWidth >= 1200) {
        this.cardsPerView = 4
      } else if (window.innerWidth >= 768) {
        this.cardsPerView = 3
      } else {
        this.cardsPerView = 1 // Only 1 card on smaller screens
      }
    }
  }
}
